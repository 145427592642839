import React from 'react';
import { injectIntl, defineMessages, IntlShape } from 'react-intl';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import ClosableDialog from '../ClosableDialog';

import { IconLocked } from '../IconSet';
import RoomLockPreferenceSwitch from './RoomLockPreferenceSwitch';


const messages = defineMessages({
  title: { id: 'userOther' }
});


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflow: 'hidden',
      paddingBottom: theme.spacing(2),
    },
    container: {
      padding: theme.spacing(1),
    },
    icons: {
      textAlign: 'center',
      alignSelf: 'center',
    }
  })
);


type Props = {
  onClose: () => void;
};


type ExtendedProps = Props & { intl: IntlShape };


function UserOther(props: ExtendedProps) {
  const classes = useStyles();

  return (
    <ClosableDialog
      open={true}
      onClose={props.onClose}
      title={props.intl.formatMessage(messages.title)}
      fullWidth
    >
      <DialogContent className={classes.root}>
        <Grid container spacing={2}>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={2} className={classes.icons}>
              <IconLocked size={24} />
            </Grid>
            <Grid item xs={10}>
              <RoomLockPreferenceSwitch />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </ClosableDialog>
  );
}


type State = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  session: any;
}


export default injectIntl(UserOther);
