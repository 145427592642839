import React from 'react';
import { useRelayEnvironment } from 'relay-hooks';
import { connect, useDispatch } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';

import { Tooltip } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { updateRoomLockPreference } from '../../lib/actions/user';
import { State } from '../../lib/reducers';

import AsyncSwitch from '../AsyncSwitch';
import { IconHelp } from '../IconSet';

const messages = defineMessages({
  roomLockPreferenceSwitchText: { id: 'roomLockPreferenceSwitchText' },
  roomLockPreferenceSwitchInfo: { id: 'roomLockPreferenceSwitchInfo' },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    userInfoEntryContent: {
      color: 'rgba(0, 0, 0, 0.90)',
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '2em',
    },
    userInfoLinkContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    userInfoLabelContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    info: {
      paddingLeft: theme.spacing(0.5)
    }
  })
);

function RoomLockPreferenceSwitch(props: ExtendedProps) {
  const {
    roomLockPreference,
    isFetching,
    isUpdating
  } = props;

  const classes = useStyles();
  const relayEnv = useRelayEnvironment();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const switchDisabled = isFetching || isUpdating;

  const handleChange = () => {
    dispatch(updateRoomLockPreference(relayEnv, !roomLockPreference));
  };

  return (
    <div className={classes.userInfoLinkContainer}>
      <div className={`${classes.userInfoEntryContent} ${classes.userInfoLabelContainer}`}>
        {formatMessage(messages.roomLockPreferenceSwitchText)}
        <Tooltip placement='top' title={formatMessage(messages.roomLockPreferenceSwitchInfo)}>
          <div className={classes.info}>
            <IconHelp size={16} />
          </div>
        </Tooltip>
      </div>
      <AsyncSwitch
        isLoading={switchDisabled}
        checked={roomLockPreference}
        onChange={handleChange}
        color="primary"
      />
    </div>
  );
}

type MappedProps = {
  roomLockPreference: State['session']['roomLockPreference'];
  isFetching: State['session']['isFetching'];
  isUpdating: State['session']['isUpdating'];
}

type Props = {
};

type ExtendedProps = Props & MappedProps;

const mapStateToProps = (state: State): MappedProps => ({
  roomLockPreference: state.session.roomLockPreference,
  isFetching: state.session.isFetching,
  isUpdating: state.session.isUpdating
});

export default connect(mapStateToProps)(RoomLockPreferenceSwitch);
