import { commitMutation } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { IEnvironment } from 'relay-runtime';

import {
  LivestreamingConfigInput,
  updateProfileMutationVariables,
  updateProfileMutation,
} from './__generated__/updateProfileMutation.graphql';


const mutation = graphql`
mutation updateProfileMutation(
  $input: UpdateProfileInput!
) {
  updateProfile(input: $input) {
    errors {key, message, reason}
    user {
      meta {
        livestreaming {
          apiKey
          apiUrl
          publishUrl
        }
        roomLockPreference
      }
    }
  }
}`;


export function updateLivestreaming(
  environment: IEnvironment,
  { apiKey, apiUrl, publishUrl }: LivestreamingConfigInput
) {
  const variables = {
    input: {
      profile: {
        meta: { livestreaming: { apiKey, apiUrl, publishUrl } }
      }
    },
  };

  return updateProfile(environment, variables);
}

export function updateRoomLockPreference(
  environment: IEnvironment,
  roomLockPreference: boolean
) {
  const variables = {
    input: {
      profile: {
        meta: { roomLockPreference }
      }
    },
  };

  return updateProfile(environment, variables);
}

export function updateProfile(environment: IEnvironment, variables: updateProfileMutationVariables) {
  return new Promise((resolve, reject) => {
    commitMutation<updateProfileMutation>(
      environment,
      {
        mutation,
        variables,
        onCompleted: ({ updateProfile }, otherErrors) => {
          if (otherErrors) {
            return reject(otherErrors);
          } else if (updateProfile && updateProfile.errors) {
            return reject(updateProfile.errors);
          }
          else if (updateProfile && updateProfile.user) {
            return resolve(updateProfile.user);
          }
          else {
            reject([]);
          }
        },
        onError: err => {
          reject(err);
        }
      }
    );
  });
}

type UpdateProfileMutationResponse = {
  meta: {
    livestreaming: {
      apiKey: string | null;
      apiUrl: string | null;
      publishUrl: string | null;
    } | null;
    roomLockPreference: boolean;
  };
}

export function getRoomLockPreference(res: UpdateProfileMutationResponse) {
  const { meta } = res;
  const { roomLockPreference } = meta;
  return roomLockPreference;
}
