import React from "react";
import clsx from 'clsx';

import { makeStyles } from "@material-ui/core/styles";
import Switch, { SwitchProps } from "@material-ui/core/Switch";
import CircularProgress from "@material-ui/core/CircularProgress";


const useStyles = makeStyles(({ palette, shadows }) => ({
  circle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 20,
    height: 20,
    borderRadius: "50%",
    backgroundColor: palette.background.default,
    boxShadow: shadows[1]
  },
  active: {
    backgroundColor: palette.secondary.main
  }
}));


function AsyncSwitch(props: SwitchProps & { isLoading: boolean }) {
  const classes = useStyles();
  const { isLoading, onChange, checked, ...restOfProps } = props;

  const effectiveState = checked;
  const [ desiredState, setDesiredState ] = React.useState(checked);

  const Icon = () => {
    const className = (effectiveState && !isLoading) ? clsx(classes.circle, classes.active) : classes.circle;
    return (
      <div className={className}>
        { isLoading && <CircularProgress size={14} color="secondary" thickness={6} /> }
      </div>
    );
  };

  const onStatusChange = (ev: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setDesiredState(ev.target.checked);
    if (onChange) {
      return onChange(ev, checked);
    }
  };

  return (
    <Switch
      checkedIcon={<Icon />}
      icon={<Icon />}
      checked={isLoading ? desiredState : effectiveState}
      onChange={onStatusChange}
      disabled={isLoading}
      { ...restOfProps }
    />
  );
}


export default AsyncSwitch;
